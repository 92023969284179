import React, { Fragment } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { Field, useForm, useFormState } from "react-final-form";
import { IEngineUnitAndChassisProtocol, IProtocolPartGroup  } from "../../../../../app/models/protocol";
import DefectButton from "../../protocolParts/defectForm/DefectButton";
import CheckboxInput from "../../../../../app/common/form/CheckboxInput";
import AutocompleteSimpleInput from "../../../../../app/common/form/AutocompleteSimpleInput";
import SelectInput from "../../../../../app/common/form/SelectInput";
import TextInput from "../../../../../app/common/form/TextInput";

interface IProps {
    group: IProtocolPartGroup;
    areaIndex: number;
    groupIndex: number;
    isEdit: boolean;
}

const CheckCoolingSystemTightnessGroup: React.FC<IProps> = ({ group, areaIndex, groupIndex, isEdit}) => {

    const form = useForm<IEngineUnitAndChassisProtocol>();
    const formState = useFormState<IEngineUnitAndChassisProtocol>();
    let area = formState.values.areas !== undefined ? formState.values.areas[areaIndex] : undefined;
    let notPerformArea = area?.notPerform || false;
    const notPerformGroup = group.notPerform || false;
    const notPerform = notPerformArea || notPerformGroup;
    
    return (
      <Fragment>
        <Row noGutters className={'mt-2 mb-2 border-bottom'}>
            <Col sm={6}>
                <h3>{group.name}</h3>
            </Col>
            <Col sm={2}></Col>
            <Col sm={2}></Col>
            <Col sm={2}>
                <Field
                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].notPerform`}
                    title="Neprovádět"
                    options={[
                        {key: 1, value: "ano"},
                        {key: 0, value: " ne "},
                    ]}
                    format={(v) => (v ? 1 : 0)}
                    parse={(v) => (!!v)}
                    type={"checkbox"}
                    initialValue={group.notPerform || false}
                    component={CheckboxInput}
                    inputOnChange={(e: any) => {
                        form.mutators.handleChangePerformAreaOrGroup(e);
                    }}
                    disabled={!isEdit || notPerform}
                    labelWidth={8}
                    valueWidth={4}
                />
            </Col>
        </Row>
   
      </Fragment>
    );
  };
  
  export default CheckCoolingSystemTightnessGroup;