import React, { Fragment } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { Field, useForm, useFormState } from "react-final-form";
import { IEngineUnitAndChassisProtocol, IProtocolPartGroup, IEngineUnitAndChassisAxleLoadWeightsGroupDataExtension } from "../../../../../app/models/protocol";
import DefectButton from "../../protocolParts/defectForm/DefectButton";
import CheckboxInput from "../../../../../app/common/form/CheckboxInput";
import AutocompleteSimpleInput from "../../../../../app/common/form/AutocompleteSimpleInput";
import SelectInput from "../../../../../app/common/form/SelectInput";
import TextInput from "../../../../../app/common/form/TextInput";

interface IProps {
    protocol: IEngineUnitAndChassisProtocol;
    group: IProtocolPartGroup;
    areaIndex: number;
    groupIndex: number;
    isEdit: boolean;
}

const CheckAxleLoadWeightsGroup: React.FC<IProps> = ({ protocol, group, areaIndex, groupIndex, isEdit}) => {

    const form = useForm<IEngineUnitAndChassisProtocol>();
    const formState = useFormState<IEngineUnitAndChassisProtocol>();
    let area = formState.values.areas !== undefined ? formState.values.areas[areaIndex] : undefined;
    let notPerformArea = area?.notPerform || false;
    const notPerformGroup = group.notPerform || false;
    const notPerform = notPerformArea || notPerformGroup;
    const dataExtension: IEngineUnitAndChassisAxleLoadWeightsGroupDataExtension = group.dataExtension;
    
    return (
      <Fragment>
        <Row noGutters className={'mt-2 mb-2 border-bottom'}>
            <Col sm={6}>
                <h3>{group.name}</h3>
            </Col>
            <Col sm={2}></Col>
            <Col sm={2}></Col>
            <Col sm={2}>
                <Field
                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].notPerform`}
                    title="Neprovádět"
                    options={[
                        {key: 1, value: "ano"},
                        {key: 0, value: " ne "},
                    ]}
                    format={(v) => (v ? 1 : 0)}
                    parse={(v) => (!!v)}
                    type={"checkbox"}
                    initialValue={group.notPerform || false}
                    component={CheckboxInput}
                    inputOnChange={(e: any) => {
                        form.mutators.handleChangePerformAreaOrGroup(e);
                    }}
                    disabled={!isEdit || notPerform}
                    labelWidth={8}
                    valueWidth={4}
                />
            </Col>
        </Row>

        <Row>
            <Col sm={12}>
                <h4>Specifikace</h4>
            </Col>
        </Row>
        <Row>
            <Col sm={12}>
                <Table className="weights">
                    <thead>
                        <tr>
                            <th>Měřený díl</th>
                            <th>PR číslo</th>
                            <th>Neodpružená hmotnost (PN)</th>
                            <th>Neodpružená hmotnost (ZN)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Pohon</td>
                            <td>
                                <Col sm={8} style={{paddingLeft: 0}}>
                                    <Field
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.drivePrNumber`}
                                        component={TextInput}
                                        initialValue={dataExtension?.drivePrNumber || ""}
                                        disabled={!isEdit || notPerform}
                                        size="sm"
                                    />
                                </Col>
                            </td>
                            <td>
                                <Row>
                                    <Col sm={8}>
                                        <Field
                                            name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.driveUnsprungWeightFrontAxle`}
                                            component={TextInput}
                                            initialValue={dataExtension?.driveUnsprungWeightFrontAxle || "0"}
                                            disabled={!isEdit || notPerform}
                                            size="sm"
                                        />
                                    </Col>
                                    <Col>Kg</Col>
                                </Row>
                            </td>
                            <td>
                                <Row>
                                    <Col sm={8}>
                                        <Field
                                            name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.driveUnsprungWeightRearAxle`}
                                            component={TextInput}
                                            initialValue={dataExtension?.driveUnsprungWeightRearAxle || "0"}
                                            disabled={!isEdit || notPerform}
                                            size="sm"
                                        />
                                    </Col>
                                    <Col>Kg</Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td>Kola</td>
                            <td>
                                <Col sm={8} style={{paddingLeft: 0}}>
                                    <Field
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.wheelsPrNumber`}
                                        component={TextInput}
                                        disabled={!isEdit || notPerform}
                                        size="sm"
                                    />
                                </Col>
                            </td>
                            <td>
                                <Row>
                                    <Col sm={8}>
                                        <Field
                                            name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.wheelsUnsprungWeightFrontAxle`}
                                            component={TextInput}
                                            disabled={!isEdit || notPerform}
                                            size="sm"
                                        />
                                    </Col>
                                    <Col>Kg</Col>
                                </Row>
                            </td>
                            <td>
                                <Row>
                                    <Col sm={8}>
                                        <Field
                                            name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.wheelsUnsprungWeightRearAxle`}
                                            component={TextInput}
                                            disabled={!isEdit || notPerform}
                                            size="sm"
                                        />
                                    </Col>
                                    <Col>Kg</Col>
                                </Row>
                            </td>
                        </tr>
                        <tr style={{ borderBottom: "2px solid #dee2e6" }}>
                            <td>Brzdy</td>
                            <td>
                                <Col sm={8} style={{paddingLeft: 0}}>
                                    <Field
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.brakesPrNumber`}
                                        component={TextInput}
                                        disabled={!isEdit || notPerform}
                                        size="sm"
                                    />
                                </Col>
                            </td>
                            <td>
                                <Row>
                                    <Col sm={8}>
                                        <Field
                                            name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.brakesUnsprungWeightFrontAxle`}
                                            component={TextInput}
                                            disabled={!isEdit || notPerform}
                                            size="sm"
                                        />
                                    </Col>
                                    <Col>Kg</Col>
                                </Row>
                            </td>
                            <td>
                                <Row>
                                    <Col sm={8}>
                                        <Field
                                            name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.brakesUnsprungWeightRearAxle`}
                                            component={TextInput}
                                            disabled={!isEdit || notPerform}
                                            size="sm"
                                        />
                                    </Col>
                                    <Col>Kg</Col>
                                </Row>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
        <Row>
            <Col sm={12}>
                <h4>Dovážení</h4>
            </Col>
        </Row>
        <Row>
            <Col sm={12}>
                <Table className="weights">
                    <thead>
                        <tr>
                            <th>Měřený díl</th>
                            <th>PR číslo</th>
                            <th>Naměřeno</th>
                            <th>Závaží</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Odstřikovače</td>
                            <td>
                                <Col sm={8} style={{paddingLeft: 0}}>
                                    <Field
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.WasherPrNumber`}
                                        component={TextInput}
                                        disabled={!isEdit || notPerform}
                                        size="sm"
                                    />
                                </Col>
                            </td>
                            <td>
                                <Row>
                                    <Col sm={8}>
                                        <Field
                                            name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.WasherMeasured`}
                                            component={TextInput}
                                            disabled={!isEdit || notPerform}
                                            size="sm"
                                        />
                                    </Col>
                                    <Col>l</Col>
                                </Row>
                            </td>
                            <td>
                                <Row>
                                    <Col sm={8}>
                                        <Field
                                            name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.WasherWeights`}
                                            component={TextInput}
                                            disabled={!isEdit || notPerform}
                                            size="sm"
                                        />
                                    </Col>
                                    <Col>Kg</Col>
                                </Row>
                            </td>
                        </tr>
                        <tr>
                            <td>Palivo</td>
                            <td>
                                <Col sm={8} style={{paddingLeft: 0}}>
                                    <Field
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.FuelPrNumber`}
                                        component={TextInput}
                                        disabled={!isEdit || notPerform}
                                        size="sm"
                                    />
                                </Col>
                            </td>
                            <td>
                                <Row>
                                    <Col sm={8}>
                                        <Field
                                            name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.FuelMeasured`}
                                            component={TextInput}
                                            disabled={!isEdit || notPerform}
                                            size="sm"
                                        />
                                    </Col>
                                    <Col>l</Col>
                                </Row>
                            </td>
                            <td>
                                <Row>
                                    <Col sm={8}>
                                        <Field
                                            name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.FuelWeights`}
                                            component={TextInput}
                                            disabled={!isEdit || notPerform}
                                            size="sm"
                                        />
                                    </Col>
                                    <Col>Kg</Col>
                                </Row>
                            </td>
                        </tr>
                        <tr style={{ borderBottom: "2px solid #dee2e6" }}>
                            <td>Močovina</td>
                            <td>
                                <Col sm={8} style={{paddingLeft: 0}}>
                                    <Field
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.AdBluePrNumber`}
                                        component={TextInput}
                                        disabled={!isEdit || notPerform}
                                        size="sm"
                                    />
                                </Col>
                            </td>
                            <td>
                                <Row>
                                    <Col sm={8}>
                                        <Field
                                            name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.AdBlueMeasured`}
                                            component={TextInput}
                                            disabled={!isEdit || notPerform}
                                            size="sm"
                                        />
                                    </Col>
                                    <Col>l</Col>
                                </Row>
                            </td>
                            <td>
                                <Row>
                                    <Col sm={8}>
                                        <Field
                                            name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.AdBlueWeights`}
                                            component={TextInput}
                                            disabled={!isEdit || notPerform}
                                            size="sm"
                                        />
                                    </Col>
                                    <Col>Kg</Col>
                                </Row>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
        <Row>
            <Col sm={12}>
                <h4>Měření hmotností</h4>
            </Col>
        </Row>
        <Row>
            <Table className="weights measurement" >
                <tr>
                    <td><label>Levá přední</label></td>
                    <td><label>Pravá přední</label></td>
                    <td><label>Přední náprava</label></td>
                    <td><label>Bez neodpružené hmotnosti</label></td>
                </tr>
                <tr>
                    <td>
                        <Row>
                            <Col sm={8}>
                                <Field
                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.WeightMeasureWheelLeftFront`}
                                    component={TextInput}
                                    disabled={!isEdit || notPerform}
                                    size="sm"
                                />
                            </Col>
                            <Col>Kg</Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={8}>
                                <Field
                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.WeightMeasureWheelRightFront`}
                                    component={TextInput}
                                    disabled={!isEdit || notPerform}
                                    size="sm"
                                />
                            </Col>
                            <Col>Kg</Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={8}>
                                <Field
                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.WeightMeasureFrontAxle`}
                                    component={TextInput}
                                    disabled={!isEdit || notPerform}
                                    size="sm"
                                />
                            </Col>
                            <Col>Kg</Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={8}>
                                <Field
                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.WeightMeasureUnsprungWeightFrontAxle`}
                                    component={TextInput}
                                    disabled={!isEdit || notPerform}
                                    size="sm"
                                />
                            </Col>
                            <Col>Kg</Col>
                        </Row>
                    </td>
                </tr>
                <tr>
                    <td><label>Levá zadní</label></td>
                    <td><label>Pravá zadní</label></td>
                    <td><label>Zadní náprava</label></td>
                    <td><label>Bez neodpružené hmotnosti</label></td>
                </tr>
                <tr>
                    <td>
                        <Row>
                            <Col sm={8}>
                                <Field
                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.WeightMeasureWheelLeftRear`}
                                    component={TextInput}
                                    disabled={!isEdit || notPerform}
                                    size="sm"
                                />
                            </Col>
                            <Col>Kg</Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={8}>
                                <Field
                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.WeightMeasureWheelRightRear`}
                                    component={TextInput}
                                    disabled={!isEdit || notPerform}
                                    size="sm"
                                />
                            </Col>
                            <Col>Kg</Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={8}>
                                <Field
                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.WeightMeasureRearAxle`}
                                    component={TextInput}
                                    disabled={!isEdit || notPerform}
                                    size="sm"
                                />
                            </Col>
                            <Col>Kg</Col>
                        </Row>
                    </td>
                    <td>
                        <Row>
                            <Col sm={8}>
                                <Field
                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.WeightMeasureUnsprungWeightRearAxle`}
                                    component={TextInput}
                                    disabled={!isEdit || notPerform}
                                    size="sm"
                                />
                            </Col>
                            <Col>Kg</Col>
                        </Row>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><label>Celková hmotnost</label></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <Row>
                            <Col sm={8}>
                                <Field
                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.WeightMeasureUnsprungWeightTotal`}
                                    component={TextInput}
                                    disabled={!isEdit || notPerform}
                                    size="sm"
                                />
                            </Col>
                            <Col>Kg</Col>
                        </Row>
                    </td>
                </tr>
            </Table>
        </Row>
        <Row>
            <Col sm={12}>
                <h4>IFA hmotnosti</h4>
            </Col>
        </Row>
        <Row>
            <Col sm={12}>
                <Table className="weights ifa">
                    <tr style={{ borderBottom: "2px solid #dee2e6" }}>
                        <td colSpan={12}><label>Odpružená hmotnost</label></td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <label>Přední náprava</label>
                        </td>
                        <td colSpan={3}>
                            <label>Zadní náprava</label>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <Row>
                                <Col sm={8}>
                                    <Field
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.IFASprungWeightFrontAxle`}
                                        component={TextInput}
                                        disabled={!isEdit || notPerform}
                                        size="sm"
                                    />
                                </Col>
                                <Col>Kg</Col>
                            </Row>
                        </td>
                        <td colSpan={3}>
                            <Row>
                                <Col sm={8}>
                                    <Field
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.IFASprungWeightRearAxle`}
                                        component={TextInput}
                                        disabled={!isEdit || notPerform}
                                        size="sm"
                                    />
                                </Col>
                                <Col>Kg</Col>
                            </Row>
                        </td>
                    </tr>
                </Table>
            </Col>
        </Row>
        <Row>
            <Col sm={12}>
                <Table className="weights ifa">
                    <tr style={{ borderBottom: "1px solid #dee2e6" }}>
                        <td colSpan={12}><label>Neodpružená hmotnost</label></td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <label>Přední náprava</label>
                        </td>
                        <td colSpan={3}>
                            <label>Zadní náprava</label>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <Row>
                                <Col sm={8}>
                                    <Field
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.IFAUnsprungWeightFrontAxle`}
                                        component={TextInput}
                                        disabled={!isEdit || notPerform}
                                        size="sm"
                                    />
                                </Col>
                                <Col>Kg</Col>
                            </Row>
                        </td>
                        <td colSpan={3}>
                            <Row>
                                <Col sm={8}>
                                    <Field
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.IFAUnsprungWeightRearAxle`}
                                        component={TextInput}
                                        disabled={!isEdit || notPerform}
                                        size="sm"
                                    />
                                </Col>
                                <Col>Kg</Col>
                            </Row>
                        </td>
                    </tr>
                </Table>
            </Col>
        </Row>
        <Row>
            <Col sm={12}><hr/></Col>
        </Row>
 
      </Fragment>
    );
  };
  
  export default CheckAxleLoadWeightsGroup;