import React, { useContext, useEffect, useState, Fragment } from "react";
import { Form, Table, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Field, Form as FinalForm } from "react-final-form";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import TextInput from "../../../app/common/form/TextInput";
import SwitchInput from "../../../app/common/form/SwitchInput";
import AlertMessage from "../../alert/AlertMessage";
import EditSaveResetButtons from "../../ButtonGroups/EditSaveResetButtons";
import { IProtocolPartGroupsSetting, IPartGroupSettingDTO, AreaEnumDTO, GroupEnumDTO } from "../../../app/models/protocol";
import NumberInput from "../../../app/common/form/NumberInput";
import SelectInput from "../../../app/common/form/SelectInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  protocolTypeId?: number;
  areaEnums?: AreaEnumDTO[];
  handleOnChangeProtocolGroups: (updatedGroups:  GroupEnumDTO[]) => void
}

const ProtocolPartGroup: React.FC<IProps> = ({ protocolTypeId, areaEnums, handleOnChangeProtocolGroups }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadProtocolPartGroupSettings,
    saveProtocolPartGroupSettings,
    protocolPartGroupRegistry,
  } = rootStore.protocolStore;
  const [protocolPartGroupSetting, setGroups] = useState<IProtocolPartGroupsSetting>();
  const [protocolCurrentGroups, setCurrentGroups] = useState<IPartGroupSettingDTO[]>([]);
  const [protocolNewGroups, setNewGroups] = useState<IPartGroupSettingDTO[]>([]);

  useEffect(() => {
    if(protocolTypeId){
      loadProtocolPartGroupSettings(protocolTypeId).then(() => {
        const partGroupSetting = protocolPartGroupRegistry.get(protocolTypeId);
        if(partGroupSetting){
          setCurrentGroups(partGroupSetting?.partGroupsSettings.map(
            (p, index: number) => {
              p.rownumber = index;
              return p;
            }
          ) || []);
          setGroups(partGroupSetting);
        }
      });
    }
  }, [loadProtocolPartGroupSettings, protocolTypeId]);

  const [edit, setEdit] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showSubmitError, setShowSubmitError] = useState(false);

  const handleSubmitForm = (values: IProtocolPartGroupsSetting) => {
    console.log(values)
    setErrors([]);
    setShowSubmitError(false);
    if(protocolTypeId){
    values.protocolTypeId = protocolTypeId;
    saveProtocolPartGroupSettings(values)
      .then(() => {
        const protocolGroupSetting = protocolPartGroupRegistry.get(protocolTypeId);
        if(protocolGroupSetting){
          setGroups(protocolGroupSetting);
          setCurrentGroups(protocolGroupSetting?.partGroupsSettings.map(
          (p, index: number) => {
            p.rownumber = index;
            return p;
          }
        ) || []);
        const updatedGroups: GroupEnumDTO[] = protocolGroupSetting?.partGroupsSettings.map((group) => ({ 
          key: group.protocolPartGroupId,
          areaId: group.protocolAreaId,
          value: group.protocolPartGroupName,
          isActive: group.isActive
        }));
        handleOnChangeProtocolGroups(updatedGroups);
        setEdit(false);
        setNewGroups([]);
      }})
      .catch((error: any) => {
        console.log(error);
        setErrors(
          error.response?.data?.errors &&
            Object.values(error.response?.data?.errors)
        );
        setShowSubmitError(true);
      });
    }
  };

  // Reset all form values to initial values
  const handleReset = (form: any) => {
    setEdit(false);
    form.reset();
  };

  const handleAddGroupManually = () => {
    var maxOrder: number = 0;
    var currentOrder: number = 0;
    var newOrder: number = 0;
    var maxRownumber: number = 0;
    var currentRownumber: number = 0;
    var newRownumber: number = 0;
    if(protocolCurrentGroups && protocolCurrentGroups.length > 0)
    {
      var localCurrentOrder = Math.max.apply(Math, protocolCurrentGroups.map(function(o) { return o.protocolPartGroupOrder; }));
      if (isFinite(localCurrentOrder)) {
        currentOrder = localCurrentOrder;
      }
      var localCurrentRownumber = Math.max.apply(Math, protocolCurrentGroups.map(function(o) { return o.rownumber; }));
      if (isFinite(localCurrentRownumber)) {
        currentRownumber = localCurrentRownumber;
      }
    }
    if(protocolNewGroups && protocolNewGroups.length > 0)
    {
      var localNewOrder = Math.max.apply(Math, protocolNewGroups.map(function(o) { return o.protocolPartGroupOrder; }))
      if (isFinite(localNewOrder)) {
        newOrder = localNewOrder;
      }
      var localNewRownumber = Math.max.apply(Math, protocolNewGroups.map(function(o) { return o.rownumber; }));
      if (isFinite(localNewRownumber)) {
        newRownumber = localNewRownumber;
      }
    }

    maxOrder = currentOrder > newOrder ? currentOrder : newOrder;
    maxRownumber = currentRownumber > newRownumber ? currentRownumber : newRownumber;

    const newGroup: IPartGroupSettingDTO = {
      protocolAreaId: undefined,
      protocolAreaName: "",
      protocolPartGroupId: 0,
      protocolPartGroupName: "",
      componentCode: "",
      isActive: true,
      protocolPartGroupOrder: maxOrder + 10,
      rownumber: maxRownumber + 1
    };
    setNewGroups([...protocolNewGroups, newGroup]);
  };

  return (
    <div>
      <FinalForm
        onSubmit={handleSubmitForm}
        initialValues={protocolPartGroupSetting}
        render={({ handleSubmit, form, values }) => (
          <Form onSubmit={handleSubmit}>
            <div className="pageheader">
              <div className="pageheader__left">
                <h3>Konfigurace skupin na protokolech</h3>
              </div>
              <div className="pageheader__right">
                {edit && (
                  <Fragment>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`tooltip-addbutton`}>Přidat skupinu</Tooltip>
                      }
                      >
                      <Button
                        style={{ marginRight: "1rem" }}
                        variant="primary"
                        onClick={() => handleAddGroupManually()}
                        className="footer__btn-add"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </OverlayTrigger>
                    </Fragment>
                )}
                {!!protocolPartGroupSetting && protocolPartGroupSetting.partGroupsSettings && (
                  <EditSaveResetButtons
                    edit={edit}
                    setEdit={setEdit}
                    handleReset={handleReset}
                    form={form}
                    isSaving={false}
                  />
                )}
              </div>
            </div>
            <AlertMessage
              type="danger"
              heading="Nastala chyba při ukládání"
              show={showSubmitError}
              setShow={setShowSubmitError}
              messageList={errors}
            />
            <div className="protocol-table">
              <Table>
                <thead>
                  <tr>
                    <th>Název oblasti</th>
                    <th>Název skupiny</th>
                    <th>Komponenta</th>
                    <th>Pořadí</th>
                    <th>Aktivní</th>
                  </tr>
                </thead>
                <tbody>
                  {protocolCurrentGroups
                    .map((setting, index) => (
                      <tr key={setting.protocolPartGroupId}>
                        <td>
                          <Field<number>
                            name={`partGroupsSettings[${setting.rownumber}].protocolAreaId`}
                            initialValue={setting.protocolAreaId}
                            options={areaEnums?.filter(x => x.isActive || (!!values?.partGroupsSettings && x.key === values?.partGroupsSettings[setting?.rownumber]?.protocolAreaId))
                              .slice()
                              .map((x) => ({
                                key: x.key,
                                value: x.value,
                                isDisabled: !x.isActive
                              }))
                            }
                            component={SelectInput}
                            isEmptyOption={false}
                            parse={(value) => value && parseInt(value)}
                            disabled={!edit}
                          />
                        </td>
                        <td>
                          <Field
                            name={`partGroupsSettings[${setting.rownumber}].protocolPartGroupId`}
                            initialValue={setting.protocolPartGroupId}
                            component="input"
                            hidden
                            disabled
                          />
                          <Field
                            name={`partGroupsSettings[${setting.rownumber}].protocolPartGroupName`}
                            initialValue={setting.protocolPartGroupName}
                            component={TextInput}
                            titleWidth={0}
                            fieldWidth={12}
                            disabled={!edit}
                            size={"sm"}
                            validate={(value) =>
                              value ? undefined : "Název nesmí být prázdný"
                            }
                          />
                        </td>
                        <td>
                          <Field
                            name={`partGroupsSettings[${setting.rownumber}].componentCode`}
                            initialValue={setting.componentCode}
                            component={TextInput}
                            titleWidth={0}
                            fieldWidth={12}
                            disabled={!edit}
                            size={"sm"}
                          />
                        </td>
                        <td className="protocol-table--order-column">
                          <Field
                            name={`partGroupsSettings[${setting.rownumber}].protocolPartGroupOrder`}
                            initialValue={setting.protocolPartGroupOrder}
                            component={NumberInput}
                            titleWidth={0}
                            fieldWidth={12}
                            disabled={!edit}
                            size={"sm"}
                            validate={(value) =>
                              value ? undefined : "Pořadí nesmí být prázdné"
                            }
                          />
                        </td>
                        <td className="protocol-table--order-column">
                          <Field
                            name={`partGroupsSettings[${setting.rownumber}].isActive`}
                            options={[
                              { key: 1, value: "ano" },
                              { key: 0, value: " ne " },
                            ]}
                            format={(v) => (v ? 1 : 0)}
                            parse={(v) => (v ? true : false)}
                            component={SwitchInput}
                            disabled={!edit}
                          />
                        </td>
                      </tr>
                    ))}
                    {edit && protocolNewGroups && protocolNewGroups?.length > 0 && (
                      protocolNewGroups?.map((newGroup, index) => (
                        <tr key={newGroup.rownumber}>
                        <td>
                          <Field<number>
                            name={`partGroupsSettings[${newGroup.rownumber}].protocolAreaId`}
                            initialValue={newGroup.protocolAreaId}
                            options={areaEnums?.filter(x => x.isActive)}
                            component={SelectInput}
                            isEmptyOption={true}
                            parse={(value) => value && parseInt(value)}
                            validate={(value) =>
                              value ? undefined : "Oblast nesmí být prázdná"
                            }
                            disabled={!edit}
                          />
                        </td>
                        <td>
                          <Field
                            name={`partGroupsSettings[${newGroup.rownumber}].protocolPartGroupId`}
                            initialValue={newGroup.protocolPartGroupId}
                            component="input"
                            hidden
                            disabled
                          />
                          <Field
                            name={`partGroupsSettings[${newGroup.rownumber}].protocolPartGroupName`}
                            initialValue={newGroup.protocolPartGroupName}
                            component={TextInput}
                            titleWidth={0}
                            fieldWidth={12}
                            disabled={!edit}
                            size={"sm"}
                            validate={(value) =>
                              value ? undefined : "Název nesmí být prázdný"
                            }
                          />
                        </td>
                        <td>
                          <Field
                            name={`partGroupsSettings[${newGroup.rownumber}].componentCode`}
                            initialValue={newGroup.componentCode}
                            component={TextInput}
                            titleWidth={0}
                            fieldWidth={12}
                            disabled={!edit}
                            size={"sm"}
                          />
                        </td>
                        <td className="protocol-table--order-column">
                          <Field
                            name={`partGroupsSettings[${newGroup.rownumber}].protocolPartGroupOrder`}
                            initialValue={newGroup.protocolPartGroupOrder}
                            component={NumberInput}
                            titleWidth={0}
                            fieldWidth={12}
                            disabled={!edit}
                            size={"sm"}
                            validate={(value) =>
                              value ? undefined : "Pořadí nesmí být prázdné"
                            }
                          />
                        </td>
                        </tr>
                    )))}
                </tbody>
              </Table>
            </div>
            <div className="pageheader">
              <div className="pageheader__left">
              </div>
              <div className="pageheader__right">
                {edit && (
                  <Fragment>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`tooltip-addbutton`}>Přidat skupinu</Tooltip>
                      }
                      >
                      <Button
                        style={{ marginRight: "1rem" }}
                        variant="primary"
                        onClick={() => handleAddGroupManually()}
                        className="footer__btn-add"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </OverlayTrigger>
                    </Fragment>
                )}
                {!!protocolPartGroupSetting && protocolPartGroupSetting.partGroupsSettings && protocolPartGroupSetting.partGroupsSettings.length > 0 && (
                  <EditSaveResetButtons
                    edit={edit}
                    setEdit={setEdit}
                    handleReset={handleReset}
                    form={form}
                    isSaving={false}
                  />
                )}
              </div>
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default observer(ProtocolPartGroup);
