import "./engineUnitAndChassisProtocol.scss";

import React, {Fragment, useContext, useState} from "react";
import {Field, Form as FinalForm} from "react-final-form";
import {Link, RouteComponentProps} from "react-router-dom";
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import EditSaveResetButtons from "../../../ButtonGroups/EditSaveResetButtons";
import {RootStoreContext} from "../../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";
import Can from "../../../../authorization/Can";
import Loader from "../../../loader/Loader";
import logo from "../../../../assets/images/Skoda_Wordmark_RGB_Emerald_Green.svg";
import {FormApi} from "final-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import AlertMessage from "../../../alert/AlertMessage";
import TextAreaInput from "../../../../app/common/form/TextAreaInput";
import {defectMutator} from "../protocolParts/DefectMutator";
import {
    useEngineUnitAndChassisProtocolQuery,
} from "../../../../app/api/client";
import {IEngineUnitAndChassisProtocol} from "../../../../app/models/protocol";
import {engineUnitAndChassisMutators} from "./Mutators";
import ProtocolHeaderCarInfo from "../../partialProtocols/protocolParts/ProtocolHeaderCarInfo";
import AdditionalInfoArea from "./AdditionalInfoArea"
import DefectsCount from "../protocolParts/DefectsCount";
import DefectsArea from "../protocolParts/DefectsArea";
import ProtocolOverviewArea from "./ProtocolOverviewArea";
import AggregateAuditAarea from "./AggregateAuditAarea/AggregateAuditAarea";
import ChassisAuditArea from "./ChassisAuditArea/ChassisAuditArea";
import CheckAdjustmentArea from "./CheckAdjustmentArea/CheckAdjustmentArea"
import SpecificTestsArea from "./SpecificTestsArea/SpecificTestsArea"
import DrivingTestArea from "./DrivingTestArea/DrivingTestArea"


interface PartialProtocolParams {
    protocolTypeId: string;
    carId: string;
    carTestId: string;
    carProtocolId: string;
}


const EngineUnitAndChassisProtocol: React.FC<RouteComponentProps<PartialProtocolParams>> = ({
    match,
    history,
}) => {
const rootStore = useContext(RootStoreContext);
const {user} = rootStore.authStore;

const [edit, setEdit] = useState(false);

const [errors, setErrors] = useState([]);
const [showSubmitError, setShowSubmitError] = useState(false);

const protocolTypeId = Number(match.params.protocolTypeId);
const carId = Number(match.params.carId);
const carTestId = Number(match.params.carTestId);
const carProtocolId = Number(match.params.carProtocolId);

const {
    data: engineUnitChassisProtocol,
    isFetching: isLoading
  } = useEngineUnitAndChassisProtocolQuery(carId, carTestId, protocolTypeId, carProtocolId);

  const handleFinalFormSubmit = async (protocol: IEngineUnitAndChassisProtocol) => {

    setErrors([]);
    setShowSubmitError(false);

};

// Reset all form values to initial values
const handleReset = (form: FormApi<IEngineUnitAndChassisProtocol>) => {
    form.reset();
    setEdit(false);
};

if (isLoading) {
    return <Loader/>
}

return (
    <div id="EngineUnitAndChassisProtocol" className="container" style={{marginBottom: "3rem"}} >

        <div className="printheader">
            <div className="printheader__left">
                <div className="printheader--image">
                    <img src={logo} alt='logo'/>
                </div>
            </div>
            <div className="printheader__right">
                <div className="text--header">Zkouškový protokol</div>
                <div className="text--text">Pracovní skupina{" "} 
                    <span className="text--expert-group">{engineUnitChassisProtocol?.carTest.expertGroupName}</span>
                </div>
            <div className="text--text">
                <span>{engineUnitChassisProtocol?.placeCode}</span> {engineUnitChassisProtocol?.placeDescription}
            </div>
            </div>
        </div>

        <div className="printfooter">
            <Table>
                <tbody>
                    <tr>
                        <td colSpan={11}>
                            {engineUnitChassisProtocol?.carTest.placeCode}{" "}
                            {engineUnitChassisProtocol?.car?.modelVdsCode || ""} / 1_01 &emsp; ŠKODA
                            AUTO a.s., Tř. Václava Klementa 869, 293 01 Mladá Boleslav II.,
                            Česká republika
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>{engineUnitChassisProtocol?.documentName}</td>
                        <td>{engineUnitChassisProtocol?.registrationNumber}</td>
                        <td>{engineUnitChassisProtocol?.storageMethod}</td>
                        <td>{engineUnitChassisProtocol?.shreddingSign}</td>
                        <td colSpan={2}>{engineUnitChassisProtocol?.shreddingTriggerEvent}</td>
                        <td>{engineUnitChassisProtocol?.classification}</td>
                        <td>{engineUnitChassisProtocol?.storageLocation}</td>
                    </tr>
                </tbody>
            </Table>
        </div>

        <table className="table-page-content">
            <thead>
                <tr>
                    <td>
                        {/* <!--placeholder for the fixed-position header--> */}
                        <div className="page-header-space"></div>
                    </td>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>
                        <div>
                            <FinalForm<IEngineUnitAndChassisProtocol>
                                onSubmit={handleFinalFormSubmit}
                                initialValues={engineUnitChassisProtocol}
                                mutators={{...engineUnitAndChassisMutators, ...defectMutator}}
                                render={({handleSubmit, values, form}) => (
                                    <Form onSubmit={handleSubmit} id="protocol-form">
                                        <div className="pageheader  no-print">
                                            <div className="pageheader__left">
                                                <h1>Zkouškový protokol</h1>
                                                <h3>Pracovní skupina{" "}<span className="text--expert-group">{engineUnitChassisProtocol?.carTest.expertGroupName}</span>{" "}</h3>
                                            </div>
                                            <div className="pageheader__right">
                                                <Can roleId={user?.userRoleId} perform="riding-noises-protocol:edit" data={{ userExpertGroupId: user?.expertGroupId }}
                                                    yes={
                                                            <EditSaveResetButtons
                                                            edit={edit}
                                                            setEdit={setEdit}
                                                            handleReset={handleReset}
                                                            form={form}
                                                            isSaving={false}
                                                            printButton={true}
                                                            />
                                                        }
                                                        no={
                                                            <Button
                                                            variant="outline-info"
                                                            type="button"
                                                            onClick={() => window.print()}
                                                            >
                                                                <FontAwesomeIcon icon={faPrint} title="Tisk"/>{" "} Tisk
                                                            </Button>
                                                        }
                                                />
                                                {!edit && (
                                                    <Fragment>
                                                        <Button
                                                            variant="outline-secondary"
                                                            style={{ marginLeft: "1rem" }}
                                                            key={"CarDetail_" + engineUnitChassisProtocol?.car.carId}
                                                            as={Link}
                                                            to={`/car/${engineUnitChassisProtocol?.car.carId}`}
                                                        >
                                                            Detail vozu
                                                        </Button>
                                                        <Button
                                                            variant="outline-secondary"
                                                            style={{ marginLeft: "1rem" }}
                                                            key={ "CarTest_" + engineUnitChassisProtocol?.carTest.carTestId }
                                                            as={Link}
                                                            to={`/carTest/${engineUnitChassisProtocol?.carTest.carTestId}`}
                                                        >
                                                            Detail zkoušky
                                                        </Button>
                                                    </Fragment>
                                                )}
                                            </div>
                                        </div>

                                        <AlertMessage type="danger" heading="Nastala chyba při ukládání protokolu" show={showSubmitError} setShow={setShowSubmitError} messageList={errors} />

                                        <div className="pagecontent">
                                            {!isLoading && engineUnitChassisProtocol && (
                                                <ProtocolHeaderCarInfo
                                                    car={engineUnitChassisProtocol.car}
                                                    carTest={engineUnitChassisProtocol.carTest}
                                                    measurementDate={undefined}
                                                />
                                            )}

                                            <div className="protocol">

                                                {engineUnitChassisProtocol?.additionalInfoArea &&
                                                    <AdditionalInfoArea isEdit={edit}
                                                                        additionalInfo={engineUnitChassisProtocol?.additionalInfoArea}
                                                    />
                                                }

                                                {engineUnitChassisProtocol?.protocolOverviewArea &&
                                                    <ProtocolOverviewArea protocol={engineUnitChassisProtocol}
                                                                    isEdit={edit}
                                                                    isDisabled={values.protocolOverviewArea?.notPerform || false}                                                                  
                                                    />
                                                }

                                                {engineUnitChassisProtocol && engineUnitChassisProtocol.areas && engineUnitChassisProtocol.areas?.map((area, index) => {
                                                    if(area.componentCode && area.componentCode === 'AggregateAuditAarea')
                                                    {
                                                        return <AggregateAuditAarea area={area} areaIndex={index} isEdit={edit} />
                                                    }
                                                    else if (area.componentCode && area.componentCode === 'ChassisAuditArea')
                                                    {
                                                        return <ChassisAuditArea protocol={engineUnitChassisProtocol} area={area} areaIndex={index} isEdit={edit} />
                                                    }
                                                    else if (area.componentCode && area.componentCode === 'CheckAdjustmentArea')
                                                    {
                                                        return <CheckAdjustmentArea area={area} areaIndex={index} isEdit={edit} />
                                                    }
                                                    else if (area.componentCode && area.componentCode === 'SpecificTestsArea')
                                                    {
                                                        return <SpecificTestsArea area={area} areaIndex={index} isEdit={edit} />
                                                    }
                                                    else if (area.componentCode && area.componentCode === 'DrivingTestArea')
                                                    {
                                                        return <DrivingTestArea area={area} areaIndex={index} isEdit={edit} />
                                                    }
                                                })}

                                                {/*Tabulka zavad*/}
                                                {!isLoading && engineUnitChassisProtocol && engineUnitChassisProtocol?.defectsArea && (
                                                    <DefectsArea isEdit={edit} isDisabled={ values.defectsArea?.notPerform || false } />
                                                )}

                                                {/*Poznamka k celkovemu protokolu*/}
                                                {!isLoading && (
                                                    <div style={{paddingTop: "1em"}}>
                                                        <h2>Poznámka</h2>
                                                        <Field<string>
                                                            name="note"
                                                            component={TextAreaInput}
                                                            disabled={!edit}
                                                            rows={5}
                                                        />
                                                    </div>
                                                )}

                                                {!isLoading && engineUnitChassisProtocol && (
                                                    <DefectsCount />
                                                )}

                                                {!isLoading && engineUnitChassisProtocol && (
                                                    <Table style={{marginTop: "50px"}}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{border: "0"}}>
                                                                    <Form.Group controlId={"defectsCount"}>
                                                                        <Row>
                                                                            <Col xs={3}>
                                                                                <Form.Label>
                                                                                    Odpovědná osoba:
                                                                                </Form.Label>
                                                                            </Col>
                                                                            <Col xs={5} style={{ textAlign: "center", borderBottom: "1px solid lightgray" }} >
                                                                                <Form.Label>
                                                                                    { engineUnitChassisProtocol.workersExecutingTest }
                                                                                </Form.Label>
                                                                            </Col>
                                                                        </Row>
                                                                    </Form.Group>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                )}
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            />
                        </div>
                    </td>
                </tr>
            </tbody>

            <tfoot>
                <tr>
                    <td>
                        <div className="page-footer-space"></div>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
);
};

export default observer(EngineUnitAndChassisProtocol);
