import React, {useContext, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Field, Form as FinalForm} from "react-final-form";

import TextInput from "../../../app/common/form/TextInput";
import SelectInput from "../../../app/common/form/SelectInput";
import SwitchInput from "../../../app/common/form/SwitchInput";
import NumberInput from "../../../app/common/form/NumberInput";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {AreaEnumDTO, GroupEnumDTO, IPartSettingDTO} from "../../../app/models/protocol";
import {observer} from "mobx-react-lite";

import AlertMessage from "../../alert/AlertMessage";
import {useProtocolPartSettingsMutation} from "../../../app/api/client";
import {toast} from "react-toastify";
import Loader from "../../loader/Loader";

interface IProps {
    protocolPart: IPartSettingDTO,
    areasEnum: AreaEnumDTO[] | undefined;
    groupsEnum: GroupEnumDTO[] | undefined;
}
const ProtocolPartForm: React.FC<IProps> = ({protocolPart, areasEnum, groupsEnum}) => {
  const rootStore = useContext(RootStoreContext);
  const {mutateAsync: saveProtocolPartSetting, isLoading} = useProtocolPartSettingsMutation();

  const { closeModal } = rootStore.modalStore;
  const [ editedPart ] = useState(protocolPart);
  const [ errors, setErrors ] = useState([]);
  const [ showSubmitError, setShowSubmitError ] = useState(false);
  const [ selectedAreaId , setSelectedAreaId ] = useState<number>()

  const handleFinalFormSubmit = (form: IPartSettingDTO) => {
    setErrors([]);
    setShowSubmitError(false);
    saveProtocolPartSetting(form)
      .then(() => {
          toast.success(`Konfigurace protokolu byla uložena`);
          closeModal();
      })
      .catch((error: any) => {
        console.log(error);
        toast.error("Při ukládání dílu protokolu nastala chyba!");
          setErrors(
          error.response?.data?.errors &&
            Object.values(error.response?.data?.errors)
        );
        setShowSubmitError(true);
      });
  };
  if (isLoading) return <Loader/>;

  return (
    <div>
    <FinalForm
        onSubmit={handleFinalFormSubmit}
        initialValues={editedPart}
        mutators={{
          selectAreaId: (args) => {
            setSelectedAreaId(Number.parseInt(args[0].target.value))
          }
        }}
        render={({ handleSubmit, form, values }) => (
        <Form onSubmit={handleSubmit}>
            <AlertMessage
              type="danger"
              heading="Nastala chyba při ukládání"
              show={showSubmitError}
              setShow={setShowSubmitError}
              messageList={errors}
            />
            <Row>
              <Col sm={10}>
                <Field<number>
                    name={`protocolAreaId`}
                    initialValue={editedPart.protocolAreaId}
                    title="Název oblasti"
                    size="sm"
                    inputOnChange={form.mutators.selectAreaId}
                    options={areasEnum?.filter(x => x.isActive || x.key === values.protocolAreaId)
                      .slice()
                      .map((x) => ({
                        key: x.key,
                        value: x.value,
                        isDisabled: !x.isActive,
                      }))}
                    component={SelectInput}
                    isEmptyOption={true}
                    parse={(value) => value && parseInt(value)}
                    disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={10}>
                <Field<number>
                    name={`protocolPartGroupId`}
                    initialValue={editedPart.protocolPartGroupId}
                    title="Název skupiny"
                    size="sm"
                    options={groupsEnum?.slice()
                                        .filter(x => selectedAreaId === undefined || (selectedAreaId && x.areaId == selectedAreaId))
                                        .filter(x => x.isActive || x.key === values?.protocolPartGroupId)
                                        .map((x) => ({
                                            key: x.key,
                                            value: x.value,
                                            isDisabled: !x.isActive,
                                          }))
                              }
                    component={SelectInput}
                    isEmptyOption={true}
                    parse={(value) => value && parseInt(value)}
                    disabled={false}
                />
              </Col>
            </Row>
            <Row>
                <Col sm={10}>
                    <Field
                        name={`protocolPartName`}
                        initialValue={editedPart.protocolPartName}
                        component={TextInput}
                        title="Název dílu"
                        size={"sm"}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={10}>
                    <Field
                        name={`componentCode`}
                        initialValue={editedPart.componentCode}
                        component={TextInput}
                        title="Komponenta"
                        size={"sm"}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={10}>
                    <Field
                        name={`protocolPartOrder`}
                        initialValue={editedPart.protocolPartOrder}
                        component={NumberInput}
                        title="Pořadí"
                        size={"sm"}
                        />
                </Col>
            </Row>
            <Row>
                <Col sm={10}>
                    <Field
                        name={`isActive`}
                        options={[
                            { key: 1, value: "ano" },
                            { key: 0, value: " ne " },
                        ]}
                        title="Aktivní"
                        format={(v) => (v ? 1 : 0)}
                        parse={(v) => (!!v)}
                        component={SwitchInput}
                    />
                </Col>
            </Row>
            <div className="flex-row flex-center">
              <Button
                  type="submit"
                  className="btn-modal"
              >
                Uložit
              </Button>

              <Button
                type="button"
                onClick={closeModal}
                className="btn-modal"
                variant="outline-secondary"
              >
                Zrušit
              </Button>
            </div>
        </Form>
        )}
    />
    </div>
  );
};

export default observer(ProtocolPartForm);
