import React, { Fragment } from "react";
import { Row, Col, Table, Container } from "react-bootstrap";
import { Field, useForm } from "react-final-form";
import { IProtocolArea, IEngineUnitAndChassisProtocol  } from "../../../../../app/models/protocol";

import CheckConformityOfPartsGroup from "./CheckConformityOfPartsGroup"
import CheckCoolingSystemTightnessGroup from "./CheckCoolingSystemTightnessGroup"
import CheckFuelSystemTightnessGroup from "./CheckFuelSystemTightnessGroup"
import CheckBrakeSystemTightnessGroup from "./CheckBrakeSystemTightnessGroup"
import CheckWheelsBalanceGroup from "./CheckWheelsBalanceGroup"

import CheckboxInput from "../../../../../app/common/form/CheckboxInput";
import TextAreaInput from "../../../../../app/common/form/TextAreaInput";

interface IProps {
  area: IProtocolArea;
  areaIndex: number;
  isEdit: boolean;
}

const SpecificTestsArea: React.FC<IProps> = ({ area, areaIndex, isEdit}) => {

  const form = useForm<IEngineUnitAndChassisProtocol>();
  
  return (
    <Fragment>
      <Table className="protocol-table">
        <thead>
          <tr>
            <th colSpan={10} className={`headingrow`}>
              <h2>{area.name}</h2>
            </th>
            <th colSpan={2} className={`headingrow`}>
              <Field
                name={`areas[${areaIndex}].notPerform`}
                title="Neprovedeno"
                options={[
                    { key: 1, value: "ano" },
                    { key: 0, value: " ne " },
                ]}
                format={(v) => (v ? 1 : 0)}
                parse={(v) => (v ? true : false)}
                type={"checkbox"}
                initialValue={area.notPerform || false}
                component={CheckboxInput}
                inputOnChange={(e: any) => {
                    form.mutators.handleChangePerformAreaOrGroup(e);
                }}
                disabled={!isEdit}
                labelWidth={8}
                valueWidth={4}
              />
            </th>
          </tr>
        </thead>
      </Table>

      <Container className={`mt-2`}>

        {area && area.protocolPartGroups && area.protocolPartGroups?.map((group, groupIndex) => {
            if(group.componentCode && group.componentCode === 'CheckConformityOfPartsGroup')
            {
                return <CheckConformityOfPartsGroup areaIndex={areaIndex} group={group} groupIndex={groupIndex} isEdit={isEdit} />
            }
            else if (group.componentCode && group.componentCode === 'CheckCoolingSystemTightnessGroup')
            {
                return <CheckCoolingSystemTightnessGroup group={group} areaIndex={areaIndex} groupIndex={groupIndex} isEdit={isEdit} />
            }
            else if (group.componentCode && group.componentCode === 'CheckFuelSystemTightnessGroup')
            {
                return <CheckFuelSystemTightnessGroup group={group} areaIndex={areaIndex} groupIndex={groupIndex} isEdit={isEdit} />
            }
            else if (group.componentCode && group.componentCode === 'CheckBrakeSystemTightnessGroup')
            {
                return <CheckBrakeSystemTightnessGroup group={group} areaIndex={areaIndex} groupIndex={groupIndex} isEdit={isEdit} />
            }
            else if (group.componentCode && group.componentCode === 'CheckWheelsBalanceGroup')
            {
                return <CheckWheelsBalanceGroup group={group} areaIndex={areaIndex} groupIndex={groupIndex} isEdit={isEdit} />
            }
        })}

      </Container>

      <Row>
        <Col sm={12} style={{paddingTop: "1em"}}>
            <h3>{`${area.name} - Poznámka`}</h3>
            <Field<string>
                name={`areas[${areaIndex}].note`}
                component={TextAreaInput}
                initialValue={area.note}
                disabled={!isEdit}
                rows={5}
            />
        </Col>
      </Row>

    </Fragment>
  );
};

export default SpecificTestsArea;