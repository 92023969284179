import {MutableState, Tools} from "final-form";
import {IEngineUnitAndChassisProtocol} from "../../../../app/models/protocol";

export const engineUnitAndChassisMutators = {
    handleChangePerformAreaOrGroup: (args: any , state: MutableState<IEngineUnitAndChassisProtocol>, utils: Tools<IEngineUnitAndChassisProtocol>) => {
        const fieldValue: boolean = args[0].target.checked;
        const fieldName = args[0].currentTarget.name;

        let fieldNameItems = fieldName.split(".");
        let name = fieldNameItems.pop();
        let prevFieldName = fieldNameItems.join(".")

        if(name === 'perform')
        {
            utils.changeValue(state,`${prevFieldName}].notPerform`,() => !fieldValue);
        }
        if(name === 'notPerform')
        {
            utils.changeValue(state,`${prevFieldName}].perform`,() => !fieldValue);
        }
    }
};