const electroExpertGroupIds = [ 5, 12 ];
const waterResistanceExpertGroupIds = [ 6, 11 ];
const ridingNoisesExpertGroupIds = [ 4, 10 ];
const acousticsExpertGroupIds = [3, 10];
const carBodyExpertGroupIds = [ 7, 15 ];
const drivingCharacteristicsExpertGroupIds = [ 2 ]
const conformityExpertGroupIds = [ 8, 13 ]
const engineUnitChassisExpertGroupIds = [ 1, 9 ]

// role based access control rules definition
const rules = {
  expertGroupWorker: {
    static: [
      "home-page:visit",
      "cars-page:visit",
      "car-calendar-page:visit",
      "car-calendar-page:editNote",
      "car-detail-page:visit",
      "expert-group-calendar-page:changeWorker",
      "car-detail-page:editNote",
      "cartest-detail-page:visit",
      "cartest-detail-page:editWorkersSelfOnly",
      "car-search:visit",
      "blocking-event:create",
      "blocking-event:edit",
      "main-protocol:visit",
      "car-body-protocol:visit",
      "acoustics-protocol:visit",
      "electro-protocol:visit",
      "water-resistance-protocol:visit",
      "engine-driving-characteristics-protocol:visit",
      "riding-noises-protocol:visit",
      "analysisEEC-protocol:visit",
      "car-analysis:visit",
      "settings-page:visit",
      "settings-prNumbers:visit",
      "engine-unit-chassis-protocol:visit",
    ],
    dynamic: {
      "expert-group-calendar-page:visit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId } = data;
        return userExpertGroupId ? true : false;
      },

      "expert-group-detail-page:visit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId, expertGroupId } = data;
        if (!userExpertGroupId || !expertGroupId) return false;
        return userExpertGroupId === expertGroupId;
      },

      "car-detail-page:editBtn": (data: any) => {
        if (!data) return false;
        const { userPlaceId, placeId } = data;
        if (!userPlaceId || !placeId) return false;
        return userPlaceId === placeId;
      },

      "cartest-detail-page:editBtn": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId, expertGroupId } = data;
        if (!userExpertGroupId || !expertGroupId) return false;
        return userExpertGroupId === expertGroupId;
      },
      "cartest-detail-page:editWorkers": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId, expertGroupId } = data;
        if (!userExpertGroupId || !expertGroupId) return false;
        return userExpertGroupId === expertGroupId;
      },
      "cartest-detail-page:editTestState": (data: any) => {
       // console.log(data);
        if (!data) return false;
        const { userId, workerIds } = data;
        if (!userId || !workerIds) return false;
        return workerIds.includes(userId);
      },
      "car-body-protocol:edit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId } = data;
        if (!userExpertGroupId) return false;
        return carBodyExpertGroupIds.includes(userExpertGroupId);
      },
      "acoustics-protocol:edit": (data: any) => {
            if (!data) return false;
            const {userExpertGroupId} = data;
            if (!userExpertGroupId) return false;
            return acousticsExpertGroupIds.includes(userExpertGroupId);
        },
      "electro-protocol:edit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId } = data;
        if (!userExpertGroupId) return false;
        return electroExpertGroupIds.includes(userExpertGroupId);
      },
      "water-resistance-protocol:edit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId } = data;
        if (!userExpertGroupId) return false;
        return waterResistanceExpertGroupIds.includes(userExpertGroupId);
      },
      "conformity-protocol:edit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId } = data;
        if (!userExpertGroupId) return false;
        return conformityExpertGroupIds.includes(userExpertGroupId);
      },
      "riding-noises-protocol:edit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId } = data;
        if (!userExpertGroupId) return false;
        return ridingNoisesExpertGroupIds.includes(userExpertGroupId);
      },
      "driving-characteristics-protocol:edit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId } = data;
        if (!userExpertGroupId) return false;
        return drivingCharacteristicsExpertGroupIds.includes(userExpertGroupId);
      },
      "car-analysis:edit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId, expertGroupId } = data;
        if (!userExpertGroupId || !expertGroupId) return false;
        return userExpertGroupId === expertGroupId;
      },
      "engine-unit-chassis-protocol:edit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId } = data;
        if (!userExpertGroupId) return false;
        return engineUnitChassisExpertGroupIds.includes(userExpertGroupId);
      },
    },
  },

  expertGroupLeader: {
    static: [
      "home-page:visit",
      "cars-page:visit",
      "car-calendar-page:visit",
      "car-calendar-page:editNote",
      "expert-group-calendar-page:changeWorker",
      "car-detail-page:visit",
      "car-detail-page:editNote",
      "cartest-detail-page:visit",
      "expert-groups-page:visit",
      "car-search:visit",
      "blocking-event:create",
      "blocking-event:edit",
      "main-protocol:visit",
      "car-body-protocol:visit",
      "acoustics-protocol:visit",
      "electro-protocol:visit",
      "water-resistance-protocol:visit",
      "riding-noises-protocol:visit",
      "analysisEEC-protocol:visit",
      "settings-page:visit",
      "settings-prNumbers:visit",
      "settings-protocols:visit",
      "car-analysis:visit",
      "engine-driving-characteristics-protocol:visit",
      "engine-unit-chassis-protocol:visit",
    ],
    dynamic: {
      "expert-group-calendar-page:visit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId } = data;
        return userExpertGroupId ? true : false;
      },
      "expert-group-calendar-page:changeExpertGroup": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId, expertGroupId, managedExpertGroups } = data;
        if (!userExpertGroupId || !expertGroupId || !managedExpertGroups) return false;
        const managedGroups = CheckManagedGroups(managedExpertGroups, expertGroupId);
        return (userExpertGroupId === expertGroupId || managedGroups);
      },
      "expert-group-detail-page:visit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId, expertGroupId, managedExpertGroups } = data;
        if (!userExpertGroupId || !expertGroupId) return false;
        const managedGroups = CheckManagedGroups(managedExpertGroups, expertGroupId);
        return (userExpertGroupId === expertGroupId || managedGroups);
      },
      "expert-group-detail-page:edit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId, expertGroupId, managedExpertGroups } = data;
        if (!userExpertGroupId || !expertGroupId) return false;
        const managedGroups = CheckManagedGroups(managedExpertGroups, expertGroupId);
        return (userExpertGroupId === expertGroupId || managedGroups);
      },

      "car-detail-page:editBtn": (data: any) => {
        if (!data) return false;
        const { userPlaceId, placeId } = data;
        if (!userPlaceId || !placeId) return false;
        return userPlaceId === placeId;
      },

      "cartest-detail-page:editBtn": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId, expertGroupId, managedExpertGroups } = data;
        if (!userExpertGroupId || !expertGroupId) return false;
        const managedGroups = CheckManagedGroups(managedExpertGroups, expertGroupId);
        return (userExpertGroupId === expertGroupId || managedGroups);
      },
      "cartest-detail-page:editDescription": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId, expertGroupId, managedExpertGroups } = data;
        if (!userExpertGroupId || !expertGroupId) return false;
        const managedGroups = CheckManagedGroups(managedExpertGroups, expertGroupId);
        return (userExpertGroupId === expertGroupId || managedGroups);
      },
      "cartest-detail-page:editTestCharacteristics": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId, expertGroupId, managedExpertGroups } = data;
        if (!userExpertGroupId || !expertGroupId) return false;
        const managedGroups = CheckManagedGroups(managedExpertGroups, expertGroupId);
        return (userExpertGroupId === expertGroupId || managedGroups);
      },
      "cartest-detail-page:editStart": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId, expertGroupId, managedExpertGroups } = data;
        if (!userExpertGroupId || !expertGroupId) return false;
        const managedGroups = CheckManagedGroups(managedExpertGroups, expertGroupId);
        return (userExpertGroupId === expertGroupId || managedGroups);
      },
      "cartest-detail-page:editShortened": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId, expertGroupId, managedExpertGroups } = data;
        if (!userExpertGroupId || !expertGroupId) return false;
        const managedGroups = CheckManagedGroups(managedExpertGroups, expertGroupId);
        return (userExpertGroupId === expertGroupId || managedGroups);
      },
      "cartest-detail-page:editLength": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId, expertGroupId, managedExpertGroups } = data;
        if (!userExpertGroupId || !expertGroupId) return false;
        const managedGroups = CheckManagedGroups(managedExpertGroups, expertGroupId);
        return (userExpertGroupId === expertGroupId || managedGroups);
      },
      "cartest-detail-page:editWorkers": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId, expertGroupId, managedExpertGroups } = data;
        if (!userExpertGroupId || !expertGroupId) return false;
        const managedGroups = CheckManagedGroups(managedExpertGroups, expertGroupId);
        return (userExpertGroupId === expertGroupId || managedGroups);
      },
      "cartest-detail-page:editTestState": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId, expertGroupId, managedExpertGroups } = data;
        if (!userExpertGroupId || !expertGroupId) return false;
        const managedGroups = CheckManagedGroups(managedExpertGroups, expertGroupId);
        return (userExpertGroupId === expertGroupId || managedGroups);
      },
      "car-body-protocol:edit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId } = data;
        if (!userExpertGroupId) return false;
        return carBodyExpertGroupIds.includes(userExpertGroupId);
      },
      "acoustics-protocol:edit": (data: any) => {
        if (!data) return false;
        const {userExpertGroupId} = data;
        if (!userExpertGroupId) return false;
        return acousticsExpertGroupIds.includes(userExpertGroupId);
      },
      "electro-protocol:edit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId } = data;
        if (!userExpertGroupId) return false;
        return electroExpertGroupIds.includes(userExpertGroupId);
      },
      "water-resistance-protocol:edit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId } = data;
        if (!userExpertGroupId) return false;
        return waterResistanceExpertGroupIds.includes(userExpertGroupId);
      },
      "riding-noises-protocol:edit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId } = data;
        if (!userExpertGroupId) return false;
        return ridingNoisesExpertGroupIds.includes(userExpertGroupId);
      },
      "conformity-protocol:edit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId } = data;
        if (!userExpertGroupId) return false;
        return conformityExpertGroupIds.includes(userExpertGroupId);
      },
      "driving-characteristics-protocol:edit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId } = data;
        if (!userExpertGroupId) return false;
        return drivingCharacteristicsExpertGroupIds.includes(userExpertGroupId);
      },
      "car-analysis:edit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId, expertGroupId, managedExpertGroups } = data;
        if (!userExpertGroupId || !expertGroupId) return false;
        const managedGroups = CheckManagedGroups(managedExpertGroups, expertGroupId);
        return (userExpertGroupId === expertGroupId || managedGroups);
      },
      "engine-unit-chassis-protocol:edit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId } = data;
        if (!userExpertGroupId) return false;
        return engineUnitChassisExpertGroupIds.includes(userExpertGroupId);
      },
    },
  },

  planAdministrator: {
    static: [
      "home-page:visit",
      "car-calendar-page:visit",
      "car-calendar-page:editNote",
      "expert-group-calendar-page:visit",
      "expert-group-calendar-page:changeExpertGroup",
      "expert-group-calendar-page:changeWorker",
      "expert-groups-page:visit",
      "expert-groups-page:create",
      "expert-group-detail-page:visit",
      "expert-group-detail-page:edit",
      "cars-page:visit",
      "cars-page:selectcar",
      "cars-page:takein",
      "cars-page:takeout",
      "cars-page:register",
      "cars-page:create",
      "cars-page:emissionSend",
      "cars-page:emissionReceive",
      "cars-page:transport",
      "cars-page:cancelTransport",
      "plan-page:visit",
      "plan-page:plan",
      "users-page:visit",
      "users-page:create",
      "user-detail-page:visit",
      "user-detail-page:edit",
      "settings-page:visit",
      "settings-parameters:visit",
      "settings-enums:visit",
      "settings-editableEnums:visit",
      "settings-carModelParams:visit",
      "settings-copsMapping:visit",
      "settings-prNumbers:visit",
      "settings-prNumbers:edit",
      "settings-testDefinitions:visit",
      "settings-testDefinitions:edit",
      "settings-companyVacation:visit",
      "settings-companyVacation:edit",
      "car-detail-page:visit",
      "car-detail-page:editCar",
      "car-detail-page:editParams",
      "car-detail-page:editCarTests",
      "car-detail-page:editNote",
      "cartest-detail-page:visit",
      "cartest-detail-page:editExpertGroup",
      "cartest-detail-page:editDescription",
      "cartest-detail-page:editTestCharacteristics",
      "cartest-detail-page:editStart",
      "cartest-detail-page:editShortened",
      "cartest-detail-page:editLength",
      "cartest-detail-page:editWorkers",
      "cartest-detail-page:editTestState",
      "car-search:visit",
      "blocking-event:create",
      "blocking-event:edit",
      "emission-cars-page:visit",
      "car-body-protocol:visit",
      "acoustics-protocol:visit",
      "electro-protocol:visit",
      "water-resistance-protocol:visit",
      "riding-noises-protocol:visit",
      "analysisEEC-protocol:visit",
      "main-protocol:visit",
      "car-body-protocol:edit",
      "acoustics-protocol:edit",
      "electro-protocol:edit",
      "water-resistance-protocol:edit",
      "riding-noises-protocol:edit",
      "driving-characteristics-protocol:edit",
      "conformity-protocol:edit",
      "settings-protocols:visit",
      "car-analysis:visit",
      "car-analysis:edit",
      "settings-planning:visit",
      "settings-copsConditions:visit",
      "engine-driving-characteristics-protocol:visit",
      "engine-unit-chassis-protocol:visit",
      "engine-unit-chassis-protocol:edit",
    ],
    dynamic: {
      "car-detail-page:editBtn": (data: any) => {
        if (!data) return false;
        const { userPlaceId, placeId } = data;
        if (!userPlaceId || !placeId) return false;
        return userPlaceId === placeId;
      },
      "cartest-detail-page:editBtn": (data: any) => {
        if (!data) return false;
        const { userPlaceId, placeId } = data;
        if (!userPlaceId || !placeId) return false;
        return userPlaceId === placeId;
      },
    }
  },
  coordinator: {
    static: [
      "home-page:visit",
      "car-calendar-page:visit",
      "car-calendar-page:editNote",
      "expert-group-calendar-page:visit",
      "expert-group-calendar-page:changeExpertGroup",
      "expert-group-calendar-page:changeWorker",
      "expert-groups-page:visit",
      "expert-group-detail-page:visit",
      "cars-page:visit",
      "settings-page:visit",
      "settings-testDefinitions:visit",
      "settings-prNumbers:visit",
      "settings-companyVacation:visit",
      "car-detail-page:visit",
      "cartest-detail-page:visit",
      "car-search:visit",
      "main-protocol:visit",
      "car-body-protocol:visit",
      "acoustics-protocol:visit",
      "electro-protocol:visit",
      "water-resistance-protocol:visit",
      "riding-noises-protocol:visit",
      "analysisEEC-protocol:visit",
      "car-analysis:visit",
      "engine-unit-chassis-protocol:visit",
      "engine-driving-characteristics-protocol:visit",
    ],
    dynamic: {
      "car-analysis:edit": (data: any) => {
        if (!data) return false;
        const { userExpertGroupId, expertGroupId } = data;
        if (!userExpertGroupId || !expertGroupId) return false;
        return userExpertGroupId === expertGroupId;
      },
    },
  },

  emissionManager: {
    static: [
      "home-page:visit",
      "cars-page:visit",
      "emission-cars-page:visit",
      "car-detail-page:visit",
      "car-search:visit",
      "car-calendar-page:visit",
      "car-calendar-page:editNote",
      "car-detail-page:editNote",
      "car-detail-page:editBtn"
    ],
  },
};

export const CheckManagedGroups = (managedExpertGroups: string | undefined, groupId: number) : boolean =>  {
  if (!groupId) return false;
  const groupsIds: number[] | undefined = convertToManagedGroupsArray(managedExpertGroups);
  if(!groupsIds) return false;
  return groupsIds.includes(groupId);
};

export const convertToManagedGroupsArray = (groups: string | undefined): number[] | undefined => {
  if(!groups) return undefined;
  const groupsId = groups.split(';').map(function(item) { return parseInt(item, 10); });
  return groupsId;
};

export default rules;
