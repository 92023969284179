import './AdditionalInfoArea.scss'

import React from "react";
import moment from "moment";
import {Field} from "react-final-form";
import {Col, Container, Form, Row} from "react-bootstrap";
import TextInput from "../../../../app/common/form/TextInput";
import DateInput from "../../../../app/common/form/DateInput";
import NumberInput from "../../../../app/common/form/NumberInput";
import NullableBooleanSwitchInput from "../../../../app/common/form/NullableBooleanSwitchInput";
import {IEngineUnitAndChassisAdditionalInfoArea} from "../../../../app/models/protocol";

interface IProps {
    isEdit: boolean;
    additionalInfo: IEngineUnitAndChassisAdditionalInfoArea;
}

const AdditionalInfoArea: React.FC<IProps> = ({isEdit, additionalInfo}) => {
    return (
        <div
            className='additionalInfoArea'>
            <Container style={{paddingLeft: 0, paddingRight: 0,}}>
            <Row noGutters>
                    <Col>
                        <Field<number>
                            name={`additionalInfo.additionalInfoValue.sequenceNumber`}
                            title="Pořadové číslo"
                            initialValue={additionalInfo?.additionalInfoValue?.sequenceNumber}
                            component={NumberInput}
                            min={0}
                            parse={(value) => value && parseInt(value)}
                            disabled={!isEdit}
                        />
                    </Col>
                    <Col>
                        <Field<number>
                            name={`additionalInfo.additionalInfoValue.currentYear`}
                            title="Rok"
                            initialValue={additionalInfo?.additionalInfoValue?.currentYear}
                            component={NumberInput}
                            min={0}
                            parse={(value) => value && parseInt(value)}
                            disabled={!isEdit}
                        />
                    </Col>
                    <Col>
                        <Field
                            name="additionalInfoArea.additionalInfoValue.protocolNo"
                            title="Číslo protokolu"
                            initialValue={additionalInfo?.additionalInfoValue?.protocolNo}
                            component={TextInput}
                            disabled={!isEdit}
                        />
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        <Field
                            name="additionalInfoArea.additionalInfoValue.engineNumber"
                            title="Číslo motoru"
                            initialValue={additionalInfo?.additionalInfoValue?.engineNumber}
                            component={TextInput}
                            disabled={!isEdit}
                        />
                    </Col>
                    <Col>
                        <Field<Date>
                            name={`additionalInfoArea.additionalInfoValue.dateM200`}
                            title="Datum M200"
                            initialValue={additionalInfo?.additionalInfoValue?.dateM200}
                            format={(val) => (val ? moment(val).toDate() : undefined)}
                            component={DateInput}
                            disabled={!isEdit}
                        />
                    </Col>
                    <Col>
                        <Field
                            name="additionalInfoArea.additionalInfoValue.importanceofCar"
                            title="Důležitost vozu"
                            initialValue={additionalInfo?.additionalInfoValue?.importanceOfCar ?? "neuvedeno"}
                            component={TextInput}
                            disabled={true}
                        />
                    </Col>
                </Row>
                <Row noGutters>
                    <Col xs={2}>
                        <label>Kontrola shodnosti</label>
                    </Col>
                    <Col xs={4}>
                        <Field
                            name={`additionalInfoArea.additionalInfoValue.checkVIN`}
                            title="VIN kód"
                            size="sm"
                            disabled={!isEdit}
                            component={NullableBooleanSwitchInput}
                        />
                    </Col>
                    <Col xs={4}>
                        <Field
                            name={`additionalInfoArea.additionalInfoValue.checkEngineNumber`}
                            title="Číslo motoru"
                            size="sm"
                            disabled={!isEdit}
                            component={NullableBooleanSwitchInput}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AdditionalInfoArea;
