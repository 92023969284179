import React, { Fragment } from "react";
import { Row, Col, Table, Button } from "react-bootstrap";
import { Field, useForm, useFormState } from "react-final-form";
import { IEngineUnitAndChassisProtocol, IProtocolArea, IProtocolPartGroup } from "../../../../../app/models/protocol";
import DefectButton from "../../protocolParts/defectForm/DefectButton";
import CheckboxInput from "../../../../../app/common/form/CheckboxInput";
import AutocompleteSimpleInput from "../../../../../app/common/form/AutocompleteSimpleInput";
import SelectInput from "../../../../../app/common/form/SelectInput";
import TextInput from "../../../../../app/common/form/TextInput";

interface IProps {
    area: IProtocolArea;
    areaIndex: number;
    group: IProtocolPartGroup;
    groupIndex: number;
    isEdit: boolean;
}

const AggregateFillingsGroup: React.FC<IProps> = ({ group, areaIndex, groupIndex, isEdit}) => {

    const form = useForm<IEngineUnitAndChassisProtocol>();
    const formState = useFormState<IEngineUnitAndChassisProtocol>();
    let area = formState.values.areas !== undefined ? formState.values.areas[areaIndex] : undefined;
    let notPerformArea = area?.notPerform || false;
    const notPerformGroup = group.notPerform || false;
    const notPerform = notPerformArea || notPerformGroup;
   
    
    return (
      <Fragment>
        <Row noGutters className={'mt-2 mb-2 border-bottom'}>
            <Col sm={6}>
                <h3>{group.name}</h3>
            </Col>
            <Col sm={2}>
                <Field
                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].prNumber`}
                    title="PR číslo"
                    initialValue={group.prNumber}
                    component={TextInput}
                    disabled={true}
                    size="sm"
                    titleWidth={6}
                    fieldWidth={6}
                />
            </Col>
            <Col sm={2}></Col>
            <Col sm={2}>
                <Field
                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].notPerform`}
                    title="Neprovádět"
                    options={[
                        {key: 1, value: "ano"},
                        {key: 0, value: " ne "},
                    ]}
                    format={(v) => (v ? 1 : 0)}
                    parse={(v) => (!!v)}
                    type={"checkbox"}
                    initialValue={group.notPerform || false}
                    component={CheckboxInput}
                    inputOnChange={(e: any) => {
                        form.mutators.handleChangePerformAreaOrGroup(e);
                    }}
                    disabled={!isEdit || notPerform}
                    labelWidth={8}
                    valueWidth={4}
                />
            </Col>
        </Row>
        <Row>
            <Table id="protocol-table">
                <thead>
                    <tr className={`protocol-rule protocol-rule--header`}>
                        <td colSpan={2}><h4>Měřený díl a poloha</h4></td>
                        <td><h4>Předpis</h4></td>
                        <td><h4>Naměřeno</h4></td>
                        <td>
                            <h4>Hodnocení</h4>
                            <small className="text-muted">
                                Vyhovuje / Závada
                            </small>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {group.partRules && group.partRules.map((rule, ruleIndex) => (
                        <tr>
                            <td colSpan={2}>
                                {rule.name}
                                <div className="text-muted">
                                    {rule.additionalInfo}
                                </div>
                            </td>
                            <td>
                                {!rule.showFunctionCode && !rule.mathEvaluate && <span>{rule.functionName}</span>}
                                {rule.showFunctionCode && <span>{rule.functionCode}</span>}{" "} <span> {rule.rule} {rule.unit}</span>
                            </td>
                            <td>
                                <Field<string>
                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].partRules[${ruleIndex}].measuredValue`}
                                    component={TextInput}
                                    initialValue={rule.measuredValue}
                                    rows={1}
                                    size="sm"
                                    disabled={!isEdit || notPerform}
                                />
                            </td>
                            <td>
                                <Row>
                                    <Col sm={5} style={{ paddingRight: 3 }}>
                                    <Field<string>
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].partRules[${ruleIndex}].stringEval`}
                                        initialValue={rule.stringEval?.toString()}
                                        component={AutocompleteSimpleInput}
                                        items={["OK", "NOK"]}
                                        rows={1}
                                        size="sm"
                                        disabled={!isEdit || notPerform}
                                    />
                                    </Col>
                                    <Col sm={5} style={{ paddingLeft: 3 }}>
                                    <Field
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].partRules[${ruleIndex}].signDefect`}
                                        inputOnChange={(e: any) => {
                                        form.mutators.calculateDefects(e);
                                        }}
                                        options={[
                                        { key: null, value: "" },
                                        { key: 1, value: "1 - A1" },
                                        { key: 2, value: "2 - A" },
                                        { key: 3, value: "3 - A" },
                                        { key: 4, value: "4 - B1" },
                                        { key: 5, value: "5 - B" },
                                        { key: 6, value: "6 - C1" },
                                        { key: 7, value: "7 - C" },
                                        { key: 8, value: "8 - C" },
                                        { key: 9, value: "9 - C" },
                                        { key: 10, value: "10 - C" },
                                        ]}
                                        component={SelectInput}
                                        disabled={!isEdit || notPerform}
                                    />
                                    </Col>
                                    
                                    <Col sm={1} style={{paddingLeft: 0, paddingRight: 0}} className="no-print">
                                        <DefectButton
                                            partRule={rule}
                                            isEdit={isEdit}
                                            isDisabled={!isEdit || notPerform}
                                        />
                                    </Col>
                                    
                                </Row>
                                </td>
                                <td className="no-print">
                                    <Field
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].partRules[${ruleIndex}].notPerform`}
                                        initialValue={rule.notPerform || false}
                                        title="Neprovádět"
                                        options={[
                                        { key: 1, value: "ano" },
                                        { key: 0, value: " ne " },
                                        ]}
                                        format={(v) => (v ? 1 : 0)}
                                        parse={(v) => (v ? true : false)}
                                        type={"checkbox"}
                                        component={CheckboxInput}
                                        disabled={!isEdit}
                                        labelWidth={8}
                                        valueWidth={4}
                                    />
                                </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Row>


  
      </Fragment>
    );
  };
  
  export default AggregateFillingsGroup;